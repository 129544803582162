import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Loader from '../../components/shared/Loader'
import TimeLine from '../../components/Tickets/ManageTicket/Timeline'
import useFetch from '../../hooks/useFetch'
import { getTicket } from '../../helpers/requests/tickets'
import { DELIVERY_TITLES } from '../../constants/shippings'
import ROUTES from '../../constants/routes'
import Header from '../../components/Tickets/ManageTicket/Header'
import HeaderTitle from '../../components/shared/HeaderTitle'
import LoadingError from '../../components/shared/LoadingError'

const ManageTicket = () => {
  const { id } = useParams()
  const { push } = useHistory()
  const [ticket, setTicket] = useState({})
  const { isLoading, error } = useFetch(getTicket, setTicket, id)

  return (
    <div className="min-h-screen w-full bg-light-grey">
      <HeaderTitle
        title="Solicitudes"
        subtitle="Ver solicitud"
        goBack={() => push(ROUTES.TICKETS)}
      />
      <div className="m-8">
        {isLoading && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}
        {error && <LoadingError />}
        {!isLoading && !error && (
          <>
            <Header ticket={ticket}/>
            <div className="mt-4 flex flex-col gap-4 lg:grid lg:grid-cols-3">
              <div className="rounded-lg bg-white py-2">
                <div className="ml-8 mt-4 font-medium">Estado</div>
                <div className="h-[calc(100%-3rem)] overflow-auto">
                  <TimeLine statusLogs={ticket.TicketStatusLogs}/>
                </div>
              </div>
              <div className="col-span-2 flex flex-col gap-4 text-left text-sm">
                <div className=" rounded-lg bg-white p-8">
                  <div className="text-base font-medium">Detalle</div>
                  <div className="mt-4">
                    <div className="font-medium">Holding</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.holdingName}</div>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">Motivo</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.type}</div>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">Detalle</div>
                    <div className="mt-2 text-medium-dark-grey">{ticket.description}</div>
                  </div>
                </div>
                {ticket.package && Object.keys(ticket.package).length > 0 && (
                  <div className="rounded-lg bg-white p-8">
                    <div className="text-base font-medium">Información de envío</div>
                    <div className="grid grid-cols-3">
                      <div className="mt-4">
                        <div className="font-medium">Courier</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.courier}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">O. de transporte</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {ticket.package.trackingNumber || '-'}
                        </div>
                      </div>
                      <div className="row-span-2 mt-4">
                        <div className="font-medium">Dirección</div>
                        <div className="mt-2 text-medium-dark-grey">{ticket.package.address}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de envío</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {DELIVERY_TITLES[ticket.package.deliveryType]}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="font-medium">Tipo de servicio</div>
                        <div className="mt-2 text-medium-dark-grey">
                          {ticket.package.courierService}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ManageTicket

import React, { useState } from 'react'

import NotificationCenterSecondary from '../components/NotificationCenter/Billing/NotificationCenterSecondary'
import useFetch from '../hooks/useFetch'
import MainNotifications from '../components/NotificationCenter/General/MainNotifications'
import { getHoldings } from '../helpers/requests/companies'

const NotificationCenter = () => {
    const [isFirstView, setIsFirstView] = useState(true)
    const [holdings, setHoldings] = useState([])

    useFetch(getHoldings, setHoldings, '')

    const returnView = () => {
        if (isFirstView) return <MainNotifications holdings={holdings} />
        return <NotificationCenterSecondary />
    }
    return (
        <div className="relative flex h-screen flex-col bg-light-grey">
            <div className=" mx-10 my-5">
                <div className="mt-5 text-left text-xl font-semibold">Centro de Notificaiones</div>
                <div className="flex items-center justify-center	">
                    <div className="grid w-96 grid-cols-2 content-center">
                        <button
                            className={`${isFirstView ? 'border-b-2 border-current text-pinflag-dark' : 'text-medium-gray'
                                }`}
                            type="button"
                            onClick={() => setIsFirstView(true)}
                        >
                            General
                        </button>
                        <button
                            className={`${!isFirstView ? 'border-b-2 border-current text-pinflag-dark' : 'text-medium-gray'
                                }`}
                            type="button"
                            onClick={() => setIsFirstView(false)}
                        >
                            Cobranza
                        </button>
                    </div>
                </div>
                {returnView()}
            </div>
        </div>
    )
}

export default NotificationCenter

import * as XLSX from 'xlsx'
import { COURIERS, DOWNLOAD_EXCEL_RETURN_OPTIONS } from '../constants/billing'
import { createExcel, normalizeJsonData } from '../utils/files'

const parseChilexpress = (json, courier, columns) => {
  const {tracking, length, height, width, weight, volume, billed} = columns
  const uniqueClientReferences = json.map((item) => {
    let weightUsed = 0
    // eslint-disable-next-line react-func/max-combined-conditions
    if (item[length] > 60 || item[width] > 60 || item[height] > 60) {
      weightUsed = item[volume]
    } else {
      weightUsed = item[weight]
    }
    const trackingNumber = String(item[tracking])
    return {
      trackingNumber: trackingNumber.includes('OTR-') ? trackingNumber.replace('OTR-', '') : trackingNumber,
      length: item[length],
      height: item[height],
      width: item[width],
      weightBilled: Math.ceil(weightUsed),
      billed: Math.ceil(item[billed]),
      courierId: COURIERS[courier]
    }
  })
  return uniqueClientReferences
}

const checkAndReplaceCommas = (value) => {
  if (typeof value === 'number') return Math.ceil(value)
  return value.replace(',', '.')
}

const getWeight = (item, weight, volume) => {
  const formattedWeight = parseFloat(checkAndReplaceCommas(item[weight]))
  const formattedVolume = parseFloat(checkAndReplaceCommas(item[volume]))
  if (formattedWeight >= formattedVolume) return Math.ceil(formattedWeight)
  return Math.ceil(formattedVolume)
}

const parseBlueExpress = (json, courier, columns) => {
  const {tracking, weight, volume, billed} = columns
  const uniqueClientReferences = json.map((item) => ({
    trackingNumber: String(item[tracking]),
    weightBilled: getWeight(item, weight, volume),
    volume: item[volume],
    billed: Math.ceil(checkAndReplaceCommas(item[billed])),
    courierId: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const parseShippify = (json, courier, columns) => {
  const {tracking, weight, billed} = columns
  const uniqueClientReferences = json.map((item) => ({
    trackingNumber: String(item[tracking]),
    weightBilled: Math.ceil(item[weight]),
    volume: 0,
    billed: Math.ceil(item[billed]),
    courierId: COURIERS[courier]
  }))
  return uniqueClientReferences
}

function getOrderUrbano(order) {
  return order.replace(/Y/g, '')
}

const parseUrbano = (json, courier, columns) => {
  const {tracking, weight, volume, billed} = columns
  const uniqueClientReferences = json.map((item) => ({
    trackingNumber: String(getOrderUrbano(item[tracking])),
    weightBilled: getWeight(item, weight, volume),
    volume: Math.ceil(parseFloat(item[volume])),
    billed: Math.ceil(parseFloat(item[billed].replace('.', ''))),
    courierId: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const recibeloBilledPrice = (item, rate) => {
  if (!item) return Math.ceil(rate)
  const price =
    item.includes('ND') ||
    item.includes('Next Day') ||
    item.includes('next day') ||
    item.includes('nd') ||
    item.includes('NextDay') ||
    item.includes('nextday')
      ? 2300
      : Math.ceil(rate)
  return price
}

const parseRecibelo = (json, courier, columns) => {
  const {tracking, billed, rate} = columns
  const uniqueClientReferences = json.map((item) => ({
    trackingNumber: String(item[tracking]),
    weightBilled: 1,
    volume: 0,
    billed: recibeloBilledPrice(item[billed], item[rate]),
    courierId: COURIERS[courier]
  }))
  return uniqueClientReferences
}

const courierParsers = {
  chilexpress: parseChilexpress,
  blueexpress: parseBlueExpress,
  shippify: parseShippify,
  urbano: parseUrbano,
  recibelo: parseRecibelo
}

const sheetOptions = {
  urbano: { sheetIndex: 0, options: { range: 7 } },
  recibelo: { sheetIndex: 0, options: {} },
  default: { sheetIndex: 1, options: {} }
}

function removeDuplicatesByTrackingNumber(packages) {
  const seenTrackingNumbers = new Set()
  return packages.filter((sale) => {
    if (seenTrackingNumbers.has(sale.trackingNumber)) {
      return false
    }
    seenTrackingNumbers.add(sale.trackingNumber)
    return true
  })
}

export const toJson = async (courier, workbook) => {
  const { sheetIndex, options } = sheetOptions[courier] || sheetOptions.default
  const sheetName = workbook.SheetNames[sheetIndex]
  const workSheet = workbook.Sheets[sheetName]

  const ordersData = XLSX.utils.sheet_to_json(workSheet, options)
  return normalizeJsonData(ordersData)
}

export const parseJsonOrders = (courier, ordersData, columns) => {
  const parserFunction = courierParsers[courier]

  if (!parserFunction) {
    throw new Error('Courier Desconocido')
  }

  const parsedData = parserFunction(ordersData, courier, columns)
  if (!parsedData[0]?.trackingNumber) throw new Error('Tracking number no encontrada')
  if (!parsedData[0]?.billed) throw new Error('Cantidad cobrada no encontrado')
  if (!parsedData[0]?.weightBilled) throw new Error('Peso cobrado no encontrado')
  return removeDuplicatesByTrackingNumber(parsedData)
}

const parseExportedExcel = (jsonData) => {
  const parsedData = jsonData.map((row) => ({
    Courier: row.courier,
    Guia: row.trackingNumber,
    Comuna: row.cityName,
    'Fecha Entrega': row.arrivalDate,
    'Peso Declarado': row.declaredWeight,
    'Peso Cobrado': row.weightBilled,
    Cobrado: row.billed,
    'Courier Price': row.courierPrice,
    'Servicio Courier': row.courierService,
    'Extras': row.extras,
    'Seguro': row.insurance,
    'Base Price': row.basePrice,
    'Aprobado Peso': row.approvedByWeight,
    'Aprobado Precio': row.approvedByPrice,
    'Revisión': row.review
  }))
  return parsedData
}

export const handleDownload = (jsonData, name) => {
  const parsedData = parseExportedExcel(jsonData)
  const rejectedPackages = parsedData.filter(
    (parcel) => !parcel['Aprobado Peso'] || !parcel['Aprobado Precio']
  )
  createExcel(parsedData, 'Sheet1', `${name}_RESULTADO`)
  createExcel(rejectedPackages, 'Sheet1', `${name}_RECHAZADOS_RESULTADO`)
}

const parseBillingsData = (jsonData) => {
  const parsedData = jsonData.map((row) => ({
    Holding: row['Holding.name'],
    Período: `${row.month}-${row.year}`,
    Expira: row.expires,
    Estado: row.status,
    'Cobrado Courier': row.netValue,
    'Cobrado por Pinflag': row.pinflagCharge,
    'Base price': row.basePriceTotal,
    Folio: row.invoiceNumber,
    'URL folio': row.invoiceUrl,
    'URL recibo': row.receiptUrl
  }))
  return parsedData
}

export const handleBillingsDownload = (jsonData) => {
  const parsedData = parseBillingsData(jsonData)
  if (parsedData.length < 1) return DOWNLOAD_EXCEL_RETURN_OPTIONS.NO_DATA
  createExcel(parsedData, 'Sheet1', 'Detalle')
  return DOWNLOAD_EXCEL_RETURN_OPTIONS.SUCCESFUL
}

import opsClient from '../../axiosConfig/opsClient'
import {  HANDLE_HOLDINGS_OPS_URL, HANDLE_NOTIFICATIONS_URL } from '../../constants/endpointUrls/api'

export const getHolding = async (queryParams) => {
  const response = await opsClient.get(`${HANDLE_HOLDINGS_OPS_URL}${queryParams}`)
  return response.data
}

export const getNotifications = async () => {
  const response = await opsClient.get(HANDLE_NOTIFICATIONS_URL)
  return response.data
}

export const createNotification = async (data) => {
  const response = await opsClient.post(HANDLE_NOTIFICATIONS_URL, data)
  return response.data
}

export const updateNotifications = async (data) => {
  const response = await opsClient.put(HANDLE_NOTIFICATIONS_URL, data)
  return response
}

export const deleteNotification = async (data) => {
  const response = await opsClient.delete(`${HANDLE_NOTIFICATIONS_URL}?notificationId=${data}`)
  return response
}
